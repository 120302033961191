const fadeOut = () => {
  const body = document.querySelector('body');
  body.style.opacity = '0';
  body.style.transition = 'opacity 0.5s';
};

const fadeIn = () => {
  const body = document.querySelector('body');
  body.style.opacity = '1';
};

window.addEventListener('mousewheel', handleScroll);
window.addEventListener('DOMMouseScroll', handleScroll);
//window.addEventListener('scroll', handleScroll)

function handleScroll(e) {
  console.log("running");
  fadeOut();
  
  setTimeout(() => {
    // Redirect to works.html after fade out
    window.location.href = 'http://mclaughlindev.com/works.html'; // Replace with the correct URL
  }, 400); // Wait for fade-out animation to complete before redirecting

  window.removeEventListener('mousewheel', handleScroll);
  window.removeEventListener('DOMMouseScroll', handleScroll);
  
  return false;
}